<template>
  <div class="reporcontent">
    <div class="content__l">
      <div class="content__l_text">
        <div class="content__l_banner">
          <img :src="initData.apparatusFileId" alt="" />
          <div class="banner_text">
            <div class="mask"></div>

            <div class="textCntanier">
              <div class="title">{{ initData.apparatusType }}</div>
              <div class="text">{{ initData.apparatusName }}</div>
              <div class="box">
                <div
                  class="box_text"
                  v-for="(i, index) in initData.apparatusTag"
                  :key="index"
                >
                  {{ i }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-html="initData.detail"></div>
      </div>
      <div class="content__l_like">
        <div class="content_text-bottom">
          <div class="cl">
            <i class="iconfont icon-heart"></i>
          </div>

          <p>{{ initData.commentPros }}人已赞</p>
        </div>
      </div>
      <div class="content__l_recommend">
        <div class="content__l_recommend-header">相关推荐</div>
        <div class="content__l_recommend-item">
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
            v-if="swiperOptions.slidesPerView"
          >
            <swiper-slide v-for="(item, index) in bannerList" :key="index">
              <div class="item">
                <div class="item-top">
                  <img :src="item.apparatusFileId" alt="" />
                </div>
                <div class="item-bottom">{{ item.apparatusName }}</div>
              </div>
            </swiper-slide>
          </swiper>
          <div>
            <el-button
              icon="el-icon-arrow-right"
              circle
              @click="swiperNext"
            ></el-button>
          </div>
        </div>
      </div>
      <div class="content__l_inputText">
        <div class="inputText">
          <div class="inputText_header">
            评论 <span>({{ commentList.length }})</span>
          </div>
          <div class="inputText_input">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="placeholder"
              v-model="comment"
              :disabled="userInfo == ''"
              resize="none"
            >
            </el-input>
            <div class="inputmsg" v-if="userInfo == ''">
              <p>请<span class="roge" @click="wxlogin">登录</span>后发表评论</p>
            </div>
            <div class="inputbtn">
              <el-button
                type="primary"
                @click="addNewsComment()"
                v-if="userInfo != ''"
              >
                发布评论
              </el-button>
            </div>
          </div>
          <div class="inputText_header">热门评论</div>
          <div class="userText_box">
            <div
              class="userText"
              v-for="(item, index) in commentList"
              :key="index"
            >
              <div class="userimg">
                <el-avatar :src="item.memberIcon" :size="60"></el-avatar>
              </div>
              <div class="username">{{ item.memberName }}</div>
              <div class="usercontent">
                {{ item.opinion }}
              </div>
              <div class="userTimer">
                <p>
                  <span>{{ item.time }}</span>
                </p>
                <div class="pros">
                  <span
                    class="ic"
                    @click="isLikes(item.opinionPros, 1, item.commentId)"
                  >
                    <i class="iconfont icon-pinglundianzan"></i>
                    {{ item.opinionPros }}
                  </span>
                  <span
                    class="ic"
                    @click="isLikes(item.opinionCons, 2, item.commentId)"
                  >
                    <i class="iconfont icon-pinglundianzantubiao"></i>
                    {{ item.opinionCons }}
                  </span>
                </div>
              </div>
              <div class="userText" v-if="item.child">
                <div class="userimg">
                  <el-avatar :size="60"></el-avatar>
                </div>
                <div class="username">{{ item.memberName }}</div>
                <div class="usercontent">
                  {{ item.comment }}
                </div>
                <div class="userTimer">
                  <p>
                    <span>{{ item.time }}</span>
                  </p>
                  <div class="pros">
                    <span class="ic">
                      <i class="iconfont icon-pinglundianzan"> </i>
                      {{ item.opinionPros }}
                    </span>
                    <span class="ic">
                      <i class="iconfont icon-pinglundianzantubiao"></i>
                      {{ item.opinionCons }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="userText_end">
              <p>评论已加载完毕～</p>
            </div>
            <div class="user_menu">
              <span class="box returnTop" @click="returnTop">
                <i class="iconfont icon-fanhuidingbutop"></i>
              </span>
              <span class="box star" @click="shoucang">
                <span class="icon_r">{{ initData.collectNum }}</span>
                <i class="iconfont icon-shoucang"></i>
              </span>
              <span class="box msg">
                <i class="iconfont icon-pinglun"></i>
              </span>
              <span class="box heart" @click="operate">
                <i class="iconfont icon-dianzan"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_r">
      <div class="header">
        <div class="topinfo__r">
          <div class="line"></div>
          <div class="topinfo__r_contanier">
            <div class="topinfo__r_header">
              <span>热门众测</span>
            </div>
            <div class="topinfo__r_content">
              <div v-for="(item, index) in bannerList" :key="index">
                <div class="content-img top1" v-if="index == 0">
                  <div class="box" @click="to('/check/product/info', item.id)">
                    <div class="imgBox">
                      <img :src="item.apparatusFileId" alt="" />
                      <div class="content-title top1">
                        <el-button round>点击申请</el-button>
                      </div>
                    </div>

                    <div class="text">
                      {{ item.apparatusName }}
                    </div>
                  </div>
                </div>
                <div class="content-img" v-if="index != 0">
                  <div class="box3" @click="to('/check/product/info', item.id)">
                    <div class="content-title3">
                      {{ item.apparatusName }}
                    </div>
                    <div class="content_time">
                      <i class="iconfont icon-time"></i>{{ item.time }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="topinfo__r_content">
              <div class="content-box"></div>
            </div>
            <div class="topinfo__r_contanier-bottom">
              阅读更多内容，狠戳这里
              <span @click="to('/check/product')">👉</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import api from "../api/api";
import check from "../api/check";
export default {
  data() {
    return {
      textarea2: "",
      initData: "",
      comment: "",
      placeholder: "",
      bannerList: [],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 20,
        navigation: {
          nextEl: ".prev",
          prevEl: ".next",
        },
      },
      commentList: [],
      isOrange: false,
    };
  },
  created() {
    this.assessCommentInfo();
    this.indexTopAssess();
    this.assessCommentOpinionList();
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {
    userInfo(n) {
      this.placeholder = n == "" ? "" : "请输入内容";
    },
  },
  components: {
    swiper,
    swiperSlide,
  },
  methods: {
    wxlogin() {
      this.$store.commit("wxLogin", true);
    },
    returnTop() {
      document
        .querySelector(".content__l_text")
        .scrollIntoView({ behavior: "smooth" });
    },
    to(path, id) {
      let url = `${path}`;
      let urlid = id ? `/${id}` : "";
      let allPath = `${url}${urlid}`;
      this.$router.push(`${allPath}`);
    },
    isLikes(commentCount, islike, id) {
      let data = {
        type: "assessOpinion",
        operate: islike == 1 ? "pros" : "cons",
        id,
      };
      
      api.operate(data).then((res) => {
        let result = res.data.data;
        this.commentList.forEach((item) => {
          if (item.commentId == result.commentId) {
            item.opinionPros = result.opinionPros;
            item.opinionCons = result.opinionCons;
          }
        });
      });
    },
    operate() {
      let data = {};
      // api.operate()
    },
    indexTopAssess() {
      api.indexTopAssess().then((res) => {
        let list = res.data.data.data;
        list.forEach((item) => {
          item.time = item.startTime;
        });

        this.bannerList = this.Time(list);
      });
    },
    assessCommentOpinionList() {
      let data = {
        commentId: this.$route.params.id,
      };
      check.assessCommentOpinionList(data).then((res) => {
        let data = res.data.data.data;
        data.forEach(item=>{
          item.time = item.createTime
        })
        let list = this.Time(data)
        this.commentList = list;
      });
    },
    assessCommentInfo() {
      let data = {
        commentCode: this.$route.params.code,
      };
      check.assessCommentInfo(data).then((res) => {
        this.initData = res.data.data;
      });
    },
    addNewsComment() {
      let data = {
        commentCode: this.$route.params.code,
        opinion: this.comment,
      };
      check.addACommentOpinion(data).then((res) => {
        this.comment = "";
        this.assessCommentOpinionList();
      });
    },
    shoucang() {
      let data = {
        type: "assess_comment",
        id: this.$route.params.catId,
      };
      api.collectAdd(data).then((res) => {
        if (res.msg == "success") {
          this.$message("已收藏");
        }
      });
    },
    swiperNext() {
      this.swiper.slideNext();
    },
  },
};
</script>
<style lang="scss">
.content-title {
  &.top1 {
    .el-button {
      background: scroll;
      color: #fff;
    }
  }
}
.inputText_input {
  .el-textarea {
    .el-textarea__inner {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      background: #F2F2F2;
      outline: none;
    }
  }
  div {
    text-align: right;
    .el-button {
      // margin: 25px 0;
    }
  }
}
.inputbtn {
  position: absolute;

  right: 13px;
  bottom: 13px;
  .el-button {
    margin: 0;
    background: #e6e6e6;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 9px 13px;
    border: none;
    border-radius: 3px 3px 3px 3px;
  }
}
</style>
<style lang="scss" scoped>
.reporcontent {
  display: flex;
  justify-content: space-between;
  .content__l {
    width: 859px;

    .content__l_text {
      border-radius: 7px;
      border: 1px solid #e9e9e9;
      padding: 13px 25px;
      font-size: 21px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      img {
        width: 100%;
      }
      p {
        margin: 25px 0;
      }
      .content__l_banner {
        font-size: 0;
        position: relative;
        margin-bottom: 40px;
        border-radius: 7px;
        overflow: hidden;
        .banner_text {
          top: 0;
          width: 208px;
          height: 100%;
          position: absolute;
          left: 0;
          .mask {
            width: 100%;
            height: 100%;
            background: #254055;
            opacity: 0.75;
          }
          .textCntanier {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            text-align: center;
            .title {
              font-size: 35px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #ffffff;
            }
            .text {
              font-size: 32px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #ffffff;
              width: 68px;
              text-align: left;
              margin: 0 auto;
            }
            .box {
              width: 133px;
              padding: 12px 27px;
              border: 1px solid #ffffff;
              margin: 0 auto;
              font-size: 27px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
    .content__l_like {
      .content_text-bottom {
        img {
          width: 100%;
        }
        padding: 50px;
        // border-bottom: 1px solid #f0f0f0;
        .cl {
          margin: 0 auto;
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          background: #ced3db;
          border-radius: 50%;
          box-shadow: 0px 5px 21px 1px
            rgba(
              167.00000524520874,
              201.99998795986176,
              255,
              0.5699999928474426
            );
          &:hover {
            background: #f8a37f;
            box-shadow: 0px 5px 21px 1px rgba(248, 163, 127, 0.5699999928474426);
          }
          i {
            font-size: 32px;
            color: #fff;
          }
        }
        p {
          margin-top: 17px;
          text-align: center;
        }
      }
    }
    .content__l_recommend {
      padding: 40px 0;
      border-bottom: 1px solid #f0f0f0;
      .content__l_recommend-header {
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin: 30px 0;
      }
      .content__l_recommend-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
          width: 213px;
          border-radius: 7px;
          .item-top {
            img {
              width: 100%;
              border-radius: 7px;
            }
          }
          .item-bottom {
            background: #f6f6f6;
            padding: 15px 21px;
          }
        }
      }
    }
    .content__l_inputText {
      .inputText {
        margin-top: 53px;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        .inputText_input {
          position: relative;
        }
        .inputmsg {
          position: absolute;
          left: 33px;
          top: 50%;
          transform: translateY(-50%);
          color: #888888;
          font-size: 24px;
          font-family: PingFang SC-Heavy;
          font-weight: 400;

          span {
            cursor: pointer;
            color: #0394ff;
            font-family: PingFang SC-Regular;
            font-size: 24px;
            font-weight: bold;
          }
        }
        .inputText_header {
          margin: 50px 0;
          color: #333;
          span {
            color: #999999;
          }
        }
        .userText_box {
          position: relative;
        }
        .userText {
          display: grid;
          grid-template-columns: 80px auto;
          grid-template-rows: 60px auto auto;
          font-size: 21px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 60px;
          .userimg {
            grid-row-start: 1;
            grid-row-end: 4;
          }
          .userTimer {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #bac0cf;
            border-bottom:  1px solid #F0F0F0;
            margin-bottom: 35px;
            .pros {
              width: 135px;
              display: flex;
              justify-content: space-between;
              .ic {
                cursor: pointer;
                i {
                  font-size: 15px;
                  color: #bac0cf;
                }
              }
            }
          }
        }
        .userText_end {
          padding: 63px 0;
          font-size: 19px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
        }
        .user_menu {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: -80px;
          bottom: 52px;

          .box {
            display: block;
            width: 61px;
            height: 61px;
            border-radius: 50%;
            // border: 1px solid #cee0ea;
            position: relative;
            margin-top: 24px;
            i {
              font-size: 61px;
              color: #cee0ea;
            }
            .icon_r {
              position: absolute;
              right: 0;
              display: block;
              width: 27px;
              height: 27px;
              line-height: 27px;
              text-align: center;
              background: #ff2e2e;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #fbfbfb;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .content_r {
    width: 296px;
    .header {
      .topinfo__r {
        // width: 328px;
        border-radius: 7px;
        // border-top: 5px solid #0394FF;
        box-shadow: 0px 4px 21px 1px
          rgba(
            94.0000019967556,
            94.0000019967556,
            94.0000019967556,
            0.23999999463558197
          );
        .line {
          height: 5px;
          background: #0394ff;
          border-radius: 7px 7px 0 0;
        }
        .topinfo__r_contanier {
          border: 1px solid #e9e9e9;
          .topinfo__r_header {
            padding: 10px 19px;
            span {
              padding: 5px 0;
              border-bottom: 3px solid #0092ff;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #0394ff;
            }
          }
        }

        .topinfo__r_content {
          .content-img {
            padding: 25px 19px;
            &.top1 {
              .box {
                border-radius: 7px;
                background: #ffffff;
                box-shadow: 0px 1px 24px 1px rgba(0, 0, 0, 0.07000000029802322);
                .text {
                  padding: 13px 21px;
                }
              }
            }
            .box {
              .imgBox {
                position: relative;
                img {
                  width: 100%;
                  border-radius: 7px 7px 0 0;
                }
              }
            }
            .box2 {
              display: grid;
              grid-template-columns: 50% 50%;
              grid-template-rows: auto auto;
              grid-column-gap: 15px;
              grid-row-gap: 15px;
              img {
                width: 100%;
                border-radius: 7px;
                grid-row-start: 1;
                grid-row-end: 3;
              }
              .content-title2 {
                font-size: 17px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .content-time {
                display: flex;
                align-items: flex-end;
                font-size: 16px;
                font-family: Helvetica-Regular, Helvetica;
                font-weight: 400;
                color: #b1b1b1;
              }
            }
            .box3 {
              .content-title3 {
                // padding: 20px 0;
                font-size: 19px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
              }
              .content_time {
                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #b1b1b1;
                padding: 19px 0;
                border-bottom: 1px solid #d2d4d6;
                i {
                  margin-right: 11px;
                }
              }
              &:hover {
                .content-title3 {
                  color: #0394ff;
                }
              }
            }
          }

          .content-title {
            &.top1 {
              //   width: 217px;
              position: absolute;
              padding: 13px 21px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              //   border: 1px solid #ffffff;
              font-size: 19px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
        .topinfo__r_contanier-bottom {
          padding: 9px 0;
          text-align: center;
          background: #0394ff;
          color: #fff;
          border-radius: 0 0 7px 7px;
          span {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>